// Do not remove following two lazysizes imports. If this is removed most images will not load.
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "../styles.css";

import { getCookie } from "cookies-next";
import App, { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { Provider as ReduxProvider } from "react-redux";

import { getUserLocationData } from "api/requests/getUserLocation";
import publicConfig from "config/public";
import serverConfig from "config/server";
import { textFont } from "constants/fonts";
import { setDomainCountryCode } from "redux/action-creators/config";
import { LOCATION_GEO_LOAD, LocationLoadAction } from "redux/reducers/location";
import { getDomainCountryCode } from "redux/selectors/config";
import { getHasLoadedLocationCookie } from "redux/selectors/location";
import { wrapper } from "redux/wrapper";
import addressTokenMiddleware from "server/middleware/addressTokenMiddleware";

import DatadogBrowserLogs from "components/DatadogBrowserLogs";
import Layout from "components/Layout";
import SplitRumAgent from "components/SplitRumAgent";

const LeaflyApp = ({
  Component,
  ...rest
}: AppProps<{
  disableFixedHeader?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  isAppBannerCookieDismissed?: boolean;
  showMinimalHeader?: boolean;
  /**
   * Permits passing the Datadog configuration from the server to the client.
   * This will be defined on the first page load, but not on any subsequent
   * client-side navigations.
   */
  datadog: {
    env?: string;
    version?: string;
  };
}>) => {
  const {
    store,
    props: { pageProps },
  } = wrapper.useWrappedStore(rest);

  const {
    disableFixedHeader,
    hideFooter,
    hideHeader,
    isAppBannerCookieDismissed,
    showMinimalHeader,
    datadog,
    ...remainingPageProps
  } = pageProps;

  const { pathname } = useRouter();

  return (
    <ReduxProvider store={store}>
      {!showMinimalHeader && (
        /**
         * Note: changes to metadata defined in this <Head> component should
         * also be reflected in the `generateMetadata` function in `app/layout`!
         */
        <Head>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="https://public.leafly.com/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://public.leafly.com/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="https://public.leafly.com/favicon/favicon-16x16.png"
          />
          <link
            rel="mask-icon"
            href="https://public.leafly.com/favicon/safari-pinned-tab.svg"
            color="#034638"
          />
          <link
            rel="shortcut icon"
            href="https://public.leafly.com/favicon/favicon.ico"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="Leafly" />
          <meta name="application-name" content="Leafly" />
          <meta name="theme-color" content="#034638" />
          <meta property="og:site_name" content="Leafly" key="og:site_name" />
          <meta
            key="twitter:card"
            name="twitter:card"
            content={pathname === "/" ? "app" : "summary"}
          />
          <meta name="twitter:site" content="@Leafly" key="twitter:site" />
          <meta property="og:type" content="website" key="og:type" />
          <meta name="twitter:app:country" content="US" />
          <meta name="twitter:app:id:iphone" content="416456429" />
          <meta name="twitter:app:id:ipad" content="416456429" />
          <meta name="twitter:app:id:googleplay" content="leafly.android" />
          {publicConfig.robots.noindexAll && (
            <meta name="robots" content="noindex,noarchive" />
          )}
        </Head>
      )}

      <DatadogBrowserLogs {...datadog} />
      <SplitRumAgent />
      <Layout
        disableFixedHeader={disableFixedHeader}
        hideFooter={hideFooter}
        hideHeader={hideHeader}
        isAppBannerCookieDismissed={isAppBannerCookieDismissed}
        showMinimalHeader={showMinimalHeader}
      >
        <Component {...remainingPageProps} />
      </Layout>

      <style jsx global>{`
        html {
          font-family: ${textFont.style.fontFamily};
        }
      `}</style>
    </ReduxProvider>
  );
};

LeaflyApp.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (props) => {
    const { ctx: context } = props;

    if (context.req && context.res) {
      if (await addressTokenMiddleware(context.req, context.res)) {
        /**
         * This should never result in the page actually being rendered, as the
         * above function will issue a redirect when necessary.
         */
        return { pageProps: {} };
      }

      store.dispatch(setDomainCountryCode(context.req.headers));

      const countryCode = getDomainCountryCode(store.getState());
      const hasLoadedLocationFromCookie = getHasLoadedLocationCookie(
        store.getState(),
      );

      if (!hasLoadedLocationFromCookie) {
        const userLocationData = await getUserLocationData(
          countryCode,
          context,
        );
        store.dispatch<LocationLoadAction>({
          location: userLocationData,
          type: LOCATION_GEO_LOAD,
        });
      }
    }

    // If passing either hideFooter or hideHeader as a prop on individual
    // pages, the passed prop will take precedence over these
    const hideHeader = context.query["hide_header"] === "true";
    const hideFooter = context.query["hide_footer"] === "true";

    const isAppBannerCookieDismissed = Boolean(
      getCookie("appBannerDismissed", context),
    );

    return {
      pageProps: {
        datadog:
          typeof window === "undefined"
            ? {
                env: serverConfig.datadogEnvironment,
                version: serverConfig.datadogVersion,
              }
            : {},
        hideFooter,
        hideHeader,
        isAppBannerCookieDismissed: isAppBannerCookieDismissed,
        ...(await App.getInitialProps(props)).pageProps,
      },
    };
  },
);

export default LeaflyApp;
