"use client";

import { useMemo, useState } from "react";
import dynamic from "next/dynamic";

import Placeholder, { Rect } from "components/Placeholder";

type Props = {
  filePath: string;
  height?: string;
  width?: string;
  className?: string;
  svgClassName?: string;
  testId?: string;
};

const IconSvg = ({
  filePath,
  height = "20",
  width = "20",
  className = "",
  svgClassName = "",
  testId = "svg-icon",
}: Props) => {
  const [error, setError] = useState(false);

  const Icon = useMemo(
    () =>
      dynamic<{ height: string; width: string; className: string }>(
        () => import(`../Icons/${filePath}`).catch(() => setError(true)),
        {
          loading: () => (
            <Placeholder height={height} width={width}>
              <Rect height="100%" width="100%" />
            </Placeholder>
          ),
        },
      ),
    [filePath],
  );

  return !error ? (
    <span data-testid={testId} className={className}>
      <Icon className={svgClassName} height={height} width={width} />
    </span>
  ) : (
    <></>
  );
};
export default IconSvg;
