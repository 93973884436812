import { setCookie } from "cookies-next";
import { v4 as uuidv4 } from "uuid";

import publicConfig from "config/public";
import { Cart, CartChange } from "custom-types/Cart";
import { LOAD_COOKIES, LoadCookiesAction } from "redux/action-creators/cookies";

import { ScheduleWindow } from "components/Checkout/ScheduledOrderModal/ScheduledOrderModal";

const X_LEAFLY_COOKIE_NAME = "X-Leafly-Id";

const { cookieDomain, cookieDomainCa } = publicConfig;

export type ContinueShopping = {
  dispensaryPath?: string;
  brandIsPaid?: boolean;
};
export type CartState = {
  sessionId: string;
  changes: CartChange[];
  data: Cart | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  error: any | null;
  isCheckingPhone: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  phoneError: string | null;
  selectedForDeliveryOption: boolean;
  showSmsOptInMessage: boolean;
  twilioPhoneNumber: string | null;
  selectedScheduleWindow?: ScheduleWindow;
  justAddedVariantId?: number;
  continueShopping?: ContinueShopping;
  showContinueShopping?: boolean;
};

export const initialState: CartState = {
  changes: [],
  data: null,
  error: null,
  isCheckingPhone: false,
  isLoading: false,
  isUpdating: false,
  phoneError: null,
  selectedForDeliveryOption: false,
  sessionId: "",
  showSmsOptInMessage: false,
  twilioPhoneNumber: null,
};

export const CREATE_CART_SESSION_ID = "CREATE_CART_SESSION_ID";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";
export const ADD_TO_CART_DISMISS = "ADD_TO_CART_DISMISS";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const REFRESH_CART_REQUEST = "REFRESH_CART_REQUEST";
export const REFRESH_CART_SUCCESS = "REFRESH_CART_SUCCESS";
export const REFRESH_CART_FAILURE = "REFRESH_CART_FAILURE";

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAILURE = "UPDATE_CART_FAILURE";

export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_FAILURE = "SAVE_ORDER_FAILURE";

export const SET_FOR_DELIVERY_OPTION = "SET_FOR_DELIVERY_OPTION";
export const SELECTED_SCHEDULE_WINDOW = "SELECTED_SCHEDULE_WINDOW";

export type SetDeliveryWindow = {
  type: typeof SELECTED_SCHEDULE_WINDOW;
  selectedScheduleWindow?: ScheduleWindow;
};

export type CreateCartSessionIdAction = {
  type: typeof CREATE_CART_SESSION_ID;
  countryCode: string;
};

export type AddToCartAction = {
  type: typeof ADD_TO_CART_REQUEST;
};
export type AddToCartSuccessAction = {
  type: typeof ADD_TO_CART_SUCCESS;
  variantId: number;
};
export type AddToCartFailureAction = {
  type: typeof ADD_TO_CART_FAILURE;
  error: Error;
};
export type AddToCartDismissAction = {
  type: typeof ADD_TO_CART_DISMISS;
};

export type GetCartAction = {
  type: typeof GET_CART_REQUEST;
};
export type GetCartSuccessAction = {
  type: typeof GET_CART_SUCCESS;
  cart: Cart;
  changes: CartChange[];
  continueShopping?: ContinueShopping;
  showContinueShopping?: boolean;
};
export type GetCartFailureAction = {
  type: typeof GET_CART_FAILURE;
  error: Error;
};

export type RefreshCartAction = {
  type: typeof REFRESH_CART_REQUEST;
  isLoading: boolean;
  isUpdating: boolean;
};
export type RefreshCartSuccessAction = {
  type: typeof REFRESH_CART_SUCCESS;
  cart: Cart;
  changes: CartChange[];
  isLoading: boolean;
  isUpdating: boolean;
};
export type RefreshCartFailureAction = {
  type: typeof REFRESH_CART_FAILURE;
  error: string;
  isLoading: boolean;
  isUpdating: boolean;
};

export type UpdateCartAction = {
  type: typeof UPDATE_CART_REQUEST;
  isUpdating: boolean;
};
export type UpdateCartSuccessAction = {
  type: typeof UPDATE_CART_SUCCESS;
  cart: Cart;
  changes: CartChange[];
  isLoading: boolean;
  isUpdating: boolean;
};
export type UpdateCartFailureAction = {
  type: typeof UPDATE_CART_FAILURE;
  error: string;
  isLoading: boolean;
  isUpdating: boolean;
};

export type SaveReservationSuccessAction = {
  type: typeof SAVE_ORDER_SUCCESS;
  error: string;
};
export type SaveReservationFailureAction = {
  type: typeof SAVE_ORDER_FAILURE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  payload: any;
};

export type SetForDeliveryOptionAction = {
  type: typeof SET_FOR_DELIVERY_OPTION;
  forDelivery: boolean;
};

export type CartActions =
  | CreateCartSessionIdAction
  | AddToCartAction
  | AddToCartSuccessAction
  | AddToCartFailureAction
  | AddToCartDismissAction
  | GetCartAction
  | GetCartSuccessAction
  | GetCartFailureAction
  | RefreshCartAction
  | RefreshCartSuccessAction
  | RefreshCartFailureAction
  | UpdateCartAction
  | UpdateCartSuccessAction
  | UpdateCartFailureAction
  | SaveReservationSuccessAction
  | SaveReservationFailureAction
  | SetDeliveryWindow
  | SetForDeliveryOptionAction;

const createCartId = (countryCode: string) => {
  const sessionId = uuidv4();

  setCookie(X_LEAFLY_COOKIE_NAME, sessionId, {
    domain: countryCode === "CA" ? cookieDomainCa : cookieDomain,
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    path: "/",
  });

  return sessionId;
};

export const cartReducer = (
  state = initialState,
  action: LoadCookiesAction | CartActions,
): CartState => {
  switch (action.type) {
    case LOAD_COOKIES:
      return {
        ...state,
        // * Don't override a session id that may be set via GET_CART_SUCCESS server side.
        sessionId: state.sessionId || action.cookies[X_LEAFLY_COOKIE_NAME],
      };
    case CREATE_CART_SESSION_ID:
      return {
        ...state,
        sessionId:
          state.sessionId ||
          state.data?.sessionId ||
          createCartId(action.countryCode),
      };
    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        justAddedVariantId: action.variantId,
      };
    case ADD_TO_CART_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case ADD_TO_CART_DISMISS:
      return {
        ...state,
        justAddedVariantId: undefined,
      };
    case GET_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        changes: action.changes,
        continueShopping: action.continueShopping,
        data: action.cart,
        sessionId: action.cart?.sessionId || state.sessionId,
        showContinueShopping: action.showContinueShopping,
      };
    case GET_CART_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case REFRESH_CART_REQUEST:
      return {
        ...state,
        error: initialState.error,
        isLoading: true,
        isUpdating: true,
      };
    case REFRESH_CART_SUCCESS:
      return {
        ...state,
        changes: action.changes,
        data: action.cart,
        isLoading: false,
        isUpdating: false,
      };
    case REFRESH_CART_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isUpdating: false,
      };
    case UPDATE_CART_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        data: action.cart,
        isLoading: false,
        isUpdating: false,
      };
    case UPDATE_CART_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isUpdating: false,
      };
    case SAVE_ORDER_SUCCESS:
      // * When an order is created, clear the frontend bag state.
      return {
        ...initialState,
      };
    case SAVE_ORDER_FAILURE: {
      const errors = action.payload;
      if (errors.length > 0) {
        return { ...state, error: errors };
      }
      return state;
    }
    case SET_FOR_DELIVERY_OPTION:
      return {
        ...state,
        selectedForDeliveryOption: action.forDelivery,
      };
    case SELECTED_SCHEDULE_WINDOW:
      return {
        ...state,
        selectedScheduleWindow: action.selectedScheduleWindow,
      };

    default:
      return state;
  }
};

export default cartReducer;
