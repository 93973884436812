import { combineReducers } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import cart, { CartActions } from "./cart";
import cartDispensary, { CartDispensaryActions } from "./cartDispensary";
import complianceRuleset, { ComplianceRulesActions } from "./complianceRules";
import config, { ConfigActions } from "./config";
import dispensary, { DispensaryActions } from "./dispensary";
import header, { HeaderActions } from "./header";
import location, { LocationActions } from "./location";
import order, { OrderActions } from "./order";
import search, { SearchActions } from "./search";
import toasts, { ToastsActions } from "./toasts";
import user, { UserActions } from "./user";
import weedfetti, { WeedfettiActions } from "./weedfetti";

export type HydrationState = {
  hasHydrated?: boolean;
};

const combinedReducer = combineReducers({
  cart,
  cartDispensary,
  complianceRuleset,
  config,
  dispensary,
  header,
  hydration: (state: HydrationState | undefined) => state || {},
  location,
  order,
  search,
  toasts,
  user,
  weedfetti,
});

export type RootState = ReturnType<typeof combinedReducer>;

export type HydrateAction = {
  type: typeof HYDRATE;
  payload: RootState;
};

export type AllActions =
  | CartActions
  | CartDispensaryActions
  | ComplianceRulesActions
  | ConfigActions
  | DispensaryActions
  | HeaderActions
  | HydrateAction
  | LocationActions
  | OrderActions
  | SearchActions
  | ToastsActions
  | UserActions
  | WeedfettiActions;

const rootReducer = (state: RootState | undefined, action: AllActions) => {
  // This will apply all state from the initial SSR hydration to the client's state.
  // This should only happen once when the frontend parses the initial state from SSR.
  // Subsequent calls to HYDRATE, like when you do a client-side page navigation, should fall through to: combinedReducer(state, action)
  // allowing you to handle the hydration in a reducer.
  if (action.type === HYDRATE && !state?.hydration?.hasHydrated) {
    return combinedReducer(
      {
        ...(state || {}),
        ...action.payload,
        hydration: {
          hasHydrated: true,
        },
      },
      action,
    );
  } else {
    return combinedReducer(state, action);
  }
};

export default rootReducer;
