import { createSelector } from "@reduxjs/toolkit";

import {
  CartDispensary,
  DeliveryServiceArea,
} from "custom-types/CartDispensary";
import {
  DispensaryServiceAreaRanges,
  DispensaryTagsEnum,
} from "custom-types/Dispensary";
import { RootState } from "redux/reducers/rootReducer";
import { getDomainCountryCode } from "redux/selectors/config";
import { consumeCartTotals } from "utils/calculateCartTotals";
import currencyFormatter from "utils/currencyFormatter";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import { formatSchedule } from "utils/dispensaryScheduleUtils";

const getOrderCartData = (state: RootState) => state?.order?.data;

export const getCartTotal = (state: RootState) =>
  consumeCartTotals(state.cart.data);

export const getCartDispensaryState = (state: RootState) =>
  state.cartDispensary;

export const getScheduleStatuses = (state: RootState) =>
  state.cartDispensary?.scheduleStatuses;

export const getDeliveryServiceArea = (state: RootState) =>
  state.cartDispensary.deliveryServiceArea;

export const getDispensaryTaxType = (state: RootState) =>
  state.cartDispensary?.data?.taxType || "";

export const getDispensaryTaxCalculationEnabled = (state: RootState) =>
  state.cartDispensary?.data?.taxCalculationEnabled;

export const getCheckoutDisclaimer = (state: RootState) =>
  state.cartDispensary?.checkoutDisclaimer;

export const getCartDispensaryData = createSelector(
  [getCartDispensaryState],
  (dispensaryState) => {
    return dispensaryState?.data as CartDispensary;
  },
);

/** Returns dispensary object from cartDispensary or order state */
export const getDispensary = createSelector(
  [getCartDispensaryData, getOrderCartData],
  (cartDispensary, orderCartData) => {
    const results = cartDispensary
      ? cartDispensary
      : orderCartData?.dispensaryData;
    return results || {};
  },
);

export const getOnlinePaymentsAccepted = createSelector(
  [getDispensary],
  (dispensary) => dispensary?.onlinePaymentsAccepted || false,
);

export const getInStorePaymentsAccepted = createSelector(
  [getDispensary],
  (dispensary) => dispensary?.inStorePaymentsAccepted || false,
);

export const getDeliveryPaymentMethods = createSelector(
  [getDispensary],
  (dispensary) => dispensary?.deliveryPaymentMethods || [],
);

export const getIsDispensaryDualLicense = createSelector(
  [getCartDispensaryData],
  (dispensary) =>
    isDispensaryDualLicense(dispensary?.tags as DispensaryTagsEnum[]),
);

export const getMenuLink = createSelector(
  [getCartDispensaryState],
  (cartDispensary) => cartDispensary.menuLink,
);

export const getDeliveryServiceAreaRanges = createSelector(
  [getDispensary],
  (dispensary) => {
    const deliveryServiceAreaRangesInitial = {
      freeDeliveryMinimum: Infinity,
      maxDeliveryDurationMaximum: 0,
      maxFee: 0,
      maxOrderMinimum: 0,
      minDeliveryDurationMinimum: Infinity,
      minFee: Infinity,
      minOrderMinimum: Infinity,
    };
    if (
      !dispensary.deliveryServiceAreas ||
      !dispensary.deliveryServiceAreas.length
    ) {
      /** When no delivery service areas exist, set Infinity values to 0 so they don't bubble up to the UI */
      deliveryServiceAreaRangesInitial.minFee = 0;
      deliveryServiceAreaRangesInitial.freeDeliveryMinimum = 0;
      deliveryServiceAreaRangesInitial.minOrderMinimum = 0;
      deliveryServiceAreaRangesInitial.minDeliveryDurationMinimum = 0;
      return deliveryServiceAreaRangesInitial;
    }

    return dispensary.deliveryServiceAreas.reduce(
      (
        ranges: DispensaryServiceAreaRanges,
        serviceArea: DeliveryServiceArea,
      ) => ({
        freeDeliveryMinimum: Math.min(
          Number(ranges.freeDeliveryMinimum),
          Number(serviceArea.freeDeliveryMinimum ?? Infinity),
        ),
        maxDeliveryDurationMaximum: Math.max(
          ranges.maxDeliveryDurationMaximum,
          Number(serviceArea.deliveryDurationMaximum),
        ),
        maxFee: Math.max(ranges.maxFee, Number(serviceArea.fee)),
        maxOrderMinimum: Math.max(
          ranges.maxOrderMinimum,
          Number(serviceArea.orderMinimum),
        ),
        minDeliveryDurationMinimum: Math.min(
          ranges.minDeliveryDurationMinimum,
          Number(serviceArea.deliveryDurationMinimum),
        ),
        minFee: Math.min(ranges.minFee, Number(serviceArea.fee)),
        minOrderMinimum: Math.min(
          ranges.minOrderMinimum,
          Number(serviceArea.orderMinimum),
        ),
      }),
      deliveryServiceAreaRangesInitial,
    );
  },
);

export const getDeliveryZoneRangeTypeValid = createSelector(
  [getDispensary],
  (dispensary) => {
    switch (dispensary?.deliveryZoneRangeType) {
      case "radius": {
        return dispensary?.deliveryRadiusMiles > 0;
      }
      case "postal_code": {
        return dispensary?.deliveryZonePostalCodeWhitelist?.length;
      }
      default: {
        return true;
      }
    }
  },
);

export const getDispensaryWithDeliveryServiceAreaRanges = createSelector(
  [getDispensary, getDeliveryServiceAreaRanges],
  (dispensary, deliveryServiceAreaRanges) => ({
    ...dispensary,
    deliveryServiceAreaRanges,
  }),
);

export const getDeliveryFee = createSelector(
  [getDeliveryServiceArea, getDeliveryServiceAreaRanges],
  (deliveryServiceArea, deliveryServiceAreaRanges) => {
    if (deliveryServiceArea) {
      return deliveryServiceArea.fee;
    }
    if (deliveryServiceAreaRanges) {
      return deliveryServiceAreaRanges.minFee;
    }
    return 0;
  },
);

export const getDeliveryFeeWithMinumSpend = createSelector(
  [getDeliveryServiceArea, getDeliveryServiceAreaRanges, getCartTotal],
  (deliveryServiceArea, deliveryServiceAreaRanges, cartTotal) => {
    const currentSpend =
      Number(cartTotal?.subtotal) - Number(cartTotal?.savings);
    if (
      deliveryServiceArea &&
      currentSpend < Number(deliveryServiceArea.freeDeliveryMinimum ?? Infinity)
    ) {
      return deliveryServiceArea.fee;
    }
    if (
      deliveryServiceAreaRanges &&
      currentSpend <
        Number(deliveryServiceAreaRanges.freeDeliveryMinimum ?? Infinity)
    ) {
      return deliveryServiceAreaRanges.minFee;
    }
    return 0;
  },
);

export const getDeliveryFeeWithMinumSpendFormatted = createSelector(
  [getDeliveryFeeWithMinumSpend, getDomainCountryCode],
  (fee, country) => {
    return fee ? currencyFormatter(fee, country) : "Free!";
  },
);

export const getDeliveryFeeFormatted = createSelector(
  [getDeliveryFee, getDomainCountryCode],
  (fee, country) => {
    return fee ? currencyFormatter(fee, country) : "Free!";
  },
);

export const getDeliveryOrderMinimum = createSelector(
  [getDeliveryServiceArea, getDeliveryServiceAreaRanges],
  (deliveryServiceArea, deliveryServiceAreaRanges) => {
    if (deliveryServiceArea) {
      return deliveryServiceArea.orderMinimum;
    }
    if (deliveryServiceAreaRanges) {
      return deliveryServiceAreaRanges.maxOrderMinimum;
    }
    return 0;
  },
);

export const getDispensarySchedules = createSelector(
  [getScheduleStatuses],
  (scheduleStatuses) => {
    if (!scheduleStatuses?.schedules) {
      return null;
    }

    return {
      deliveryHours: formatSchedule(scheduleStatuses.schedules, "delivery"),
      pickupHours: formatSchedule(scheduleStatuses.schedules, "pickup"),
      storeHours: formatSchedule(scheduleStatuses.schedules, "store"),
    };
  },
);

export const getCurrentStatuses = createSelector(
  [getScheduleStatuses],
  (scheduleStatuses) => {
    if (!scheduleStatuses?.currentStatuses) {
      return null;
    }
    return scheduleStatuses?.currentStatuses;
  },
);

export const getCurrentStatusIsOpenForDelivery = createSelector(
  [getScheduleStatuses, getDispensary],
  (scheduleStatuses, dispensary) =>
    !!scheduleStatuses?.currentStatuses?.delivery?.isOpen ||
    dispensary.scheduledDeliveryEnabled ||
    dispensary.isGrassdoorRetailer,
);

export const getDispensaryIsOpenForPickup = createSelector(
  [getDispensary, getScheduleStatuses],
  (dispensary, scheduleStatuses) => {
    if (!dispensary?.hasReservationsEnabled) {
      return false;
    }
    return (
      !!scheduleStatuses?.currentStatuses?.store?.isOpen &&
      !!scheduleStatuses?.currentStatuses?.pickup?.isOpen
    );
  },
);

export const getCurrentStatusIsOpenForPickup = createSelector(
  [getScheduleStatuses],
  (scheduleStatuses) => !!scheduleStatuses?.currentStatuses?.pickup?.isOpen,
);

export const getDispensaryIsOpenForPreorder = createSelector(
  [getDispensary, getScheduleStatuses],
  (dispensary, scheduleStatuses) => {
    if (
      dispensary?.hasReservationsEnabled &&
      dispensary?.preorderEnabled &&
      !scheduleStatuses?.currentStatuses?.pickup?.isOpen
    ) {
      return true;
    }
    return false;
  },
);

export const getDispensaryIsOpenForPickupOrPreorder = createSelector(
  [getDispensaryIsOpenForPickup, getDispensaryIsOpenForPreorder],
  (openForPickup, openForPreorder) => {
    return openForPickup || openForPreorder;
  },
);

export const getCurrentStatusIsOpenForPickupOrPreorder = createSelector(
  [getCurrentStatusIsOpenForPickup, getDispensaryIsOpenForPreorder],
  (openForPickup, openForPreorder) => openForPickup || openForPreorder,
);

export const getDispensaryIsClosed = createSelector(
  [getCurrentStatusIsOpenForDelivery, getDispensaryIsOpenForPickupOrPreorder],
  (deliveryOpen, openForPickupOrPreorder) => {
    return !(deliveryOpen || openForPickupOrPreorder);
  },
);

export const getCurrentStatusIsClosed = createSelector(
  [
    getCurrentStatusIsOpenForDelivery,
    getCurrentStatusIsOpenForPickupOrPreorder,
  ],
  (openForDelivery, openForPickupOrPreorder) =>
    !(openForDelivery || openForPickupOrPreorder),
);

export const getOnlyDeliveryIsOpen = createSelector(
  [
    getCurrentStatusIsOpenForDelivery,
    getDispensaryIsOpenForPickup,
    getDispensaryIsOpenForPreorder,
  ],
  (deliveryOpen, pickupOpen, preorderOpen) => {
    return deliveryOpen && !pickupOpen && !preorderOpen;
  },
);

export const getCurrentStatusOnlyDeliveryIsOpen = createSelector(
  [
    getCurrentStatusIsOpenForDelivery,
    getCurrentStatusIsOpenForPickup,
    getDispensary,
  ],
  (openForDelivery, openForPickup, dispensary) =>
    openForDelivery && !openForPickup && !dispensary.preorderEnabled,
);

export const getShowDeliveryOption = createSelector(
  [getDispensary, getDeliveryZoneRangeTypeValid],
  (dispensary, rangeValid) => dispensary?.hasDeliveryEnabled && rangeValid,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
const reqCountrySelector = (state: { country: any }) => state.country;

const dispensaryCountrySelector = createSelector(
  [getDispensary],
  (dispensary) => dispensary.country,
);

export const getCountry = createSelector(
  [dispensaryCountrySelector, reqCountrySelector],
  (dispCountry, reqCountry) => dispCountry || reqCountry,
);

export const getDispensaryState = createSelector(
  [getDispensary],
  (dispensary) => dispensary.state,
);

export const getDispensaryId = createSelector(
  [getDispensary],
  (dispensary) => dispensary.id,
);

export const getPublicEncryptionKey = createSelector(
  [getDispensary],
  (dispensary) => dispensary.publicEncryptionKey,
);

export const getScheduledDeliveryEnabled = createSelector(
  [getDispensary],
  (dispensary) => dispensary.scheduledDeliveryEnabled,
);
